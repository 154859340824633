<template>
  <div class="container mt-5">
    <h3
      data-aos-duration="2000"
      data-aos="fade-right"
      class="mb-4 header-spacing moving-gradient-text fw-bolder"
    >
      WEBSITE DESIGN & DEVELOPMENT
    </h3>

    <div class="row my-5">
      <div class="col-md-5 my-auto">
        <p class="mb-3">
          At
          <strong>friday<span class="text-pink">collective</span></strong>
          we build bespoke websites using the latest technology to provide
          lightning fast, modern and beautiful websites.
        </p>
        <p>
          All of our web solutions are backed by the
          <strong>mod<span class="text-pink">snip</span></strong> CRM, an
          intelligent customer relationship platform that integrates seamlessly
          with your website, giving you the power to capture and harness your
          data to its full potential.
        </p>

        <button class="btn btn-light moving-gradient-button btn-lg mt-3">
          Get Started
        </button>
      </div>
      <div class="col mb-4 text-center my-auto">
        <img
          src="@/assets/images/pages/full-setup.png"
          alt="Friday Collective"
          width="100%"
          data-aos-duration="2000"
          data-aos="fade-left"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h3
          class="display-5 fw-bolder header-spacing moving-gradient-text mb-3 text-center"
        >
          KICKSTART YOUR ONLINE PRESENCE
        </h3>
        <div class="row">
          <div class="col-md-6 mb-4 p-5 my-auto">
            <div class="card border-0 shadow-sm bg-light text-center">
              <div class="card-body">
                <h5 class="fw-bold moving-gradient-text mb-4">
                  WHAT DO I GET?
                </h5>
                <i class="far fa-star fa-4x text-pink mb-4"></i>
                <ul class="list-unstyled">
                  <li class="fw-bold mb-2">Web design</li>
                  <li class="fw-bold mb-2">Web development</li>
                  <li class="fw-bold mb-2">Contact / Enquiry Forms</li>
                  <li class="fw-bold mb-2">Custom Forms</li>
                  <li class="fw-bold mb-2">CRM Login</li>
                  <li class="fw-bold mb-2">Search Engine Optimisation</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6 p-5 my-auto">
            <p>
              Our team of digital specialists will work with you to design and
              build a bespoke web platform specific to your needs. We create
              beautiful, modern websites that are backed by intelligent business
              systems, creating cohesive online solutions that work for all
              departments throughout your business.
            </p>
            <p>
              All of our websites come turbo charged with the tools you need to
              seamlessly manage content and effectively engage with your user
              base.
            </p>
            <p>From front to back, we've got you covered.</p>
            <router-link
              to="/send-smoke-signal"
              class="btn moving-gradient-button btn-lg mt-3"
            >
              Get Started
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
